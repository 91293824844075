export default [
  {
    title: 'dashboards',
    icon: 'HomeIcon',
    route: 'dashboard',
    tagVariant: 'light-success',
  },
  {
    title: 'requests',
    route: 'apps-email',
    icon: 'MailIcon',
  },
  {
    title: 'contracts',
    route: 'apps-chat',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'tasks',
    route: 'apps-todo',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'suppliers',
    route: 'userproviders',
    icon: 'CalendarIcon',
  },
  {
    title: 'reports',
    icon: 'HomeIcon',
    route: 'dashboard',
    tagVariant: 'light-success',
    children: [
      {
        title: 'requests',
        route: 'dashboard-ecommerce',
      },
      {
        title: 'halfTime',
        route: 'dashboard-analytics',
      },
      {
        title: 'tasks',
        route: 'dashboard-analytics',
      },
      {
        title: 'contracts',
        route: 'dashboard-analytics',
      },
    ],
  },
  {
    title: 'setting',
    icon: 'HomeIcon',
    route: 'dashboard',
    tagVariant: 'light-success',
    children: [
      {
        title: 'users',
        route: 'setting_user',
      },
      {
        title: 'groups',
        route: 'dashboard-analytics',
      },
      {
        title: 'role',
        route: 'setting_roles',
      },
      {
        title: 'catalogs',
        route: 'dashboard-analytics',
      },
    ],
  },
  {
    title: 'help',
    icon: 'HomeIcon',
    route: 'dashboard',
    tagVariant: 'light-success',
    children: [
      {
        title: 'manuals',
        route: 'dashboard-ecommerce',
      },
      {
        title: 'tutorials',
        route: 'dashboard-analytics',
      },
    ],
  },
]
